import { XMarkIcon } from "@heroicons/react/24/solid";

export default function JobDrawer({ isOpen, onClose, jobData }) {
  if (!isOpen) return null;

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">

      {isOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="bg-white w-[100%] md:w-[50%] lg:w-[50%] rounded-lg shadow-lg p-6 max-h-[80vh] flex flex-col">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">{jobData.position}</h2>
              <button onClick={() => onClose()} className="text-gray-600 hover:text-gray-800">
                <XMarkIcon className="w-6 h-6" />
              </button>
            </div>

            <div className="p-4 flex-1 text-sm overflow-y-auto">
              <div className="mb-2">
                <i className="fas fa-map-marker-alt mr-2"></i>{jobData.jobTypeName}
              </div>
              <div className="mb-2">
                  <i className="fas fa-briefcase mr-2"></i>{jobData.typeName}
              </div>
              <div className="mb-2">
                  <i className="fas fa-university mr-2"></i>{jobData.educationLevelName}
              </div>
              <div className="mb-2">
                  <i className="fas fa-clock mr-2"></i>{jobData.experienceLevelName}
              </div>

              <h3 className="text-lg font-semibold">Requirements</h3>
              <ul className="list-disc list-inside text-gray-700 space-y-1 text-sm">
                {
                    jobData !== "" &&
                    jobData.requirements.map((item, key) => (
                        <li key={key}>{item}</li>
                    ))
                }
              </ul>
            </div>

            <div className="p-4 border-t flex justify-end space-x-2">
              <button
                onClick={() => onClose()}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
              >
                Close
              </button>
              <a href="https://forms.gle/awCrtGkH3C7EGqUy7">
                <button className="px-4 py-2 bg-secondary text-white rounded-lg">
                  Apply Now
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
