import { useEffect, useState } from "react";
import background from "../../../asset/svg/radiant-gradient.svg";
import { useParams } from "react-router-dom";
import { positionList } from "../../../components/particles/DataLists";

const Detail = () => {
    let { id } = useParams();
    const [detail, setDetail] = useState("");

    const findArrayElementByTitle = (paramId) => {
        return positionList.filter((element) => {
            return element.id === parseInt(paramId);
        });
    };

    useEffect(() => {
        setDetail(findArrayElementByTitle(id)[0]);
        window.scrollTo(0, 0);

    }, [id]);

    return (  
        <main className="relative py-[4.2rem]">
            <section className="">
                <div className="flex flex-col p-2 m-h-screen py-14 bg-no-repeat bg-cover" style={{backgroundImage: `url(${background})`}}></div>
            </section>
            <section className="relative lg:m-[10rem] md:m-[2rem]">
                <div className="container px-4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg md:-mt-36 lg:-mt-64 sm:-mt-8">
                        <div className="flex flex-wrap justify-end">
                            <div className="py-6 px-3 mt-32 sm:mt-0">
                                <span className="text-secondary text-lg">{detail.roles}</span>
                            </div>
                            <div className="px-4">   
                                <div className="py-6 px-3 mt-32 sm:mt-0">
                                    <a href="https://forms.gle/awCrtGkH3C7EGqUy7">
                                        <button className="bg-secondary uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150" type="button">
                                            Apply Now
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="px-6">
                            <div className="">
                                <h3 className="text-4xl font-semibold leading-normal">
                                    {detail.position}
                                </h3>
                                <div className="mb-2">
                                    <i className="fas fa-map-marker-alt mr-2 text-lg"></i>{detail.jobTypeName}
                                </div>
                                <div className="mb-2">
                                    <i className="fas fa-briefcase mr-2 text-lg"></i>{detail.typeName}
                                </div>
                                <div className="mb-2">
                                    <i className="fas fa-university mr-2 text-lg"></i>{detail.educationLevelName}
                                </div>
                                <div className="mb-2">
                                    <i className="fas fa-clock mr-2 text-lg"></i>{detail.experienceLevelName} Experience
                                </div>
                            </div>
                            <div className="mt-10 py-10 border-t">
                                <div className="flex flex-wrap">
                                <div className="w-full lg:w-9/12 px-4">
                                    <ul className={"pt-4 list-disc list-inside transition-opacity ease-in-out delay-150 duration-300 text-sm font-['Poppins'] space-y-2"}>
                                        {
                                            detail !== "" &&
                                            detail.requirements.map((item, key) => (
                                                <li key={key}>{item}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Detail;