export const jobList = [
    {
        id: 0,
        roles: "Software Engineering",
        list: [ 
            "Fullstack Developer", 
            "Backend Developer", 
            "Frontend Developer",
            "DevOps / DevSecOps Engineer", 
            "QA / Test Engineer",
            "Security /Network Engineer",
            "IT Infrastructure"
        ]
    },
    {
        id: 1,
        roles: "Product Management",
        list: [ 
            "Product Owner",
            "Product Design",
            "Product Manager",
            "IT Project Manager",
            "Delivery Manager"
        ]
    },
    {
        id: 2,
        roles: "Marketing & Communications",
        list: [      
            "Account Manager",
            "Digital Business Representative",
            "Social Media Specialist",
            "Account Executive",
            "Brand / Product Management",
            "Partnership Manager",
            "Graphic Designer",
            "Technical Writer"
        ]
    },
    {
        id: 3,
        roles: "Data Science",
        list: [      
            "Database Administrator",
            "Data Engineer",
            "Data Quality",
            "Data Scientist",
            "Data Steawards",
            "Data Analyst"
        ]
    },
    {
        id: 4,
        roles: "Services",
        list: [      
            "Waiters",
            "Supervisor",
            "Crew Floor",
            "Crew Kitchen",
            "Host Live "
        ]
    }
];


export const positionList = [
    {
        id: 0,
        position: "Devsecops",
        experienceLevel: 2,
        experienceLevelName: "Min 2 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Jakarta",
        educationLevel: 0,
        educationLevelName: "Senior High School/Vocational High School",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [
            "At least 2 years experience in the relevant field",
            "Experience with cloud security or other security applications",
            "Experienced in penetration testing to identify security vulnerabilities within applications",
            "Ability to perform security risk analysi",
            "Capable of collaborating with teams effectively",
            "Effective communication skill with ability to influence "
        ]
    },
    {
        id: 1,
        position: "Digital Sales Manager",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years Experience In Digital Agency",
        jobType: 0,
        jobTypeName: "On Site Jakarta",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Digital Marketing",
        requirements: [ 
            "A Bachelor’s degree in Marketing, Business, Economics, Communications, or related fields",
            "Prior experience in sales role is crucial (Minimum 3 years experienced). Candidates should have a deep understanding of the digital marketing landscape, including various channels such as social media, PPC, SEO, email marketing, etc.",
            "Minimum of 5 years of experience in digital agency and 5 years’ experience in managerial position.",
            "Proven track record of successfully closing sales deals and achieving revenue targets.",
            "Strong communication skills and negotiation skills are essential for with clients, delivering compelling sales pitches, and articulating complex digital marketing concepts in a clear and concise manner.",
            "Ability to work independently and as part of a team in a fast-paced and target-driven environment.",
            "Ability to think strategically and develop tailored solutions to meet clients' needs and objectives.",
            "Commitment to providing exceptional customer service and maintaining long-term relationships with clients.",
            "Willingness to stay updated on industry developments, emerging technologies, and evolving consumer behaviors to stay ahead of the curve and drive innovation in digital marketing strategies.",
            "Fluent in English."
        ]
    },
    {
        id: 2,
        position: "Digital Business Representative",
        experienceLevel: 3,
        experienceLevelName: "Min 3 Years Experience In Digital Agency",
        jobType: 0,
        jobTypeName: "On Site Jakarta",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Digital Marketing",
        requirements: [      
            "A Bachelor’s degree in Marketing, Business, Economics, Communications, or related fields", 
            "Prior experience in sales role or digital marketing is crucial (Minimum 3 years experienced). Candidates should have a deep understanding of the digital marketing landscape, including various channels such as social media, PPC, SEO, email marketing, etc.",
            "Proven track record of successfully closing sales deals and achieving revenue targets.",
            "Strong communication skills and negotiation skills are essential for with clients, delivering compelling sales pitches, and articulating complex digital marketing concepts in a clear and concise manner.",
            "Ability to work independently and as part of a team in a fast-paced and target-driven environment.",
            "Ability to think strategically and develop tailored solutions to meet clients' needs and objectives.",
            "Commitment to providing exceptional customer service and maintaining long-term relationships with clients.",
            "Willingness to stay updated on industry developments, emerging technologies, and evolving consumer behaviors to stay ahead of the curve and drive innovation in digital marketing strategies.",
            "Fluent in English",
        ]
    },
    {
        id: 3,
        position: "Senior Account Manager",
        experienceLevel: 5,
        experienceLevelName: "Min 8 Years Experience In Digital Agency",
        jobType: 0,
        jobTypeName: "On Site Jakarta Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "A Bachelor’s degree in Marketing, Business, Economics, Communications, or related field.", 
            "Minimum of 8 years of experience in digital marketing and 2 years experience in managerial position",
            "Proven track record of success in managing client accounts and delivering measurable results across various digital channels such as social media, PPC, SEO, email marketing, DSPs (DV360, Google Ads, TTD, etc.), Facebook Ads or TikTok ads",
            "Strong analytical skills and proficiency in leveraging data to drive decision-making and optimize campaign performance.",
            "Proven ability to identify upselling opportunities within existing client accounts and effectively communicate the value proposition of additional services or solutions.",
            "Demonstrate leadership qualities, project management skills, and the ability to mentor and coach junior team members as needed.",
            "Willingness to stay updated on industry trends, emerging technologies, and best practices in digital marketing to remain at the forefront of the industry and drive innovation in client strategies.",
            "Provide previous portfolio from previous work of performance ads is preferred.",
            "Fluent in English"
        ]
    },
    {
        id: 4,
        position: "Java Springboot Developer",
        experienceLevel: 2,
        experienceLevelName: "Min 2 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Jakarta",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "Proficiency in server-side languages (e.g., Java Spring boot) and familiarity with cloud environments (e.g.Google Cloud)", 
            "Experience in API development and integration with third-party services and other banks system",
            "Knowledge of database management (SQL, NoSQL)",
        ],
        responsibilities: [      
            "Develop and maintain digital loan processing functions, including underwriting process, disbursement, collection process.", 
            "Ensure backend systems are scalable, performant, and secure.",
            "Integrate with external services, handling API requests and responses securely and efficiently.",
        ]
    },
    {
        id: 5,
        position: "Frontend Developer (Web and Mobile)",
        experienceLevel: 2,
        experienceLevelName: "Min 2 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Jakarta",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "Proficiency in JavaScript frameworks (e.g., React, Vue.js, Angular) for web applications and React Native for mobile applications.", 
            "Experience with responsive design, UX principles, and developing secure, user friendly interfaces.",
            "Knowledge of tokenization and data exchange protocols to protect sensitive information",
        ],
        responsibilities: [      
            "Build a smooth, intuitive user interface for the Loan & collection system, focusing on ease of use and reliability.", 
            "Ensure cross-platform consistency and a seamless user experience.",
            "Work closely with backend developers to ensure integration between front-end and server-side systems.",
        ]
    },
    {
        id: 6,
        position: "Senior .NET",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years Experience In Banking Industries",
        jobType: 0,
        jobTypeName: "On Site Jakara Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "Minimum of 5 years of experience",
            "Minimum education of D3 in Information Technology, Information Systems, or Computer Science",
            "Proficient in .Net or .Net Core programming language",
            "Strong analytical, logical, and troubleshooting skills for system development",
            "Familiar with versioning systems such as GitHub, Bitbucket, SVN, etc.",
            "Experienced in developing web applications and rich client applications",
            "Possesses good character and can work well in a team",
            "Understands C# programming language with ASP.NET / .Net Core technology",
            "Capable of using SQL Server",
            "Willing to work on Banking – Loan projects"
        ],
        responsibilities: [      
            "Membangun aplikasi berbasis .net dengan menggunakan Bahasa C# / ASP.net", 
            "Monitoring dan kontrol berkala terhadap modul-modul yang sedang di kerjakan.",
            "Menyesuaikan aplikasi dengan kebutuhan user.",
            "Koordinasi dengan tim IT QA terkait defect yang terdapat pada aplikasi."
        ]
    },
    {
        id: 7,
        position: "Backend Developer (Golang, Linux, Docker, Kubernetes)",
        experienceLevel: 3,
        experienceLevelName: "Min 3 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Bandung",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "Familiar with Golang, Linux, Docker, and Kubernetes.", 
            "Familiar with REST API modules such as fiber, gin-gonic, echo, etc.",
            "Familiar with gRPC framework.",
            "Familiar with SQL queries and ORMs in Golang.",
            "Familiar with RDBMS using PostgreSQL.",
            "Familiar with NoSQL using Mongo.",
            "Familiar with caching using Redis.",
            "Familiar with asynchronous programming.",
            "Familiar with microservices architecture",
            "Mastering Git is a must.",
            "Strong knowledge of programming principles such as DDD, Clean Code, etc",
            "Capable of using cloud platform such as GCP or AWS is a plus.",
            "Candidate should be willing to work around Bandung (preference for on-site work at the office)"

        ],
        responsibilities: [      

        ]
    },
    {
        id: 8,
        position: "Next JS Developer",
        experienceLevel: 3,
        experienceLevelName: "Min 3 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Bandung",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "Familiar with NextJS and Typescript framework.", 
            "⁠Familiar with state management framework such as Redux, React, Hooks, etc.",
            "Familiar with REST API integrations.",
            "Mastering Git is a must.",
            "Strong knowledge of programming principles such as DDD, Clean Code, etc",
            "⁠Capable of slicing UI design to code is a must.",
            "Candidate should be willing to work around Bandung (preference for on-site work at the office)"

        ],
        responsibilities: [      

        ]
    },
    {
        id: 9,
        position: "SAP System Administrator",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Jakarta Pusat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "Bachelor's degree in Computer Science or related field", 
            "Minimum 5 years of experience in SAP Basis Administration",
            "Strong knowledge of SAP NetWeaver technologies",
            "Experience in installing and maintaining SAP systems",
            "Strong troubleshooting and problem-solving skills",
            "Excellent communication and interpersonal skills",
            "Ability to work independently in a fast-paced environment",
            "Ability to maintain SAP user and authorizations"
        ],
        responsibilities: [      

        ]
    },
    {
        id: 10,
        position: " Junior Data Analyst",
        experienceLevel: 1,
        experienceLevelName: "Min 1 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Jakarta Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "1-2 years of experience as a data analyst",
            "Good logic skills",
            "English language skills (to read documents)",
            "Experience in Data Visualization, ETL & Data Processing",
            "Experience in loan financing priority",
            "Experience in decision engine priority",
            "Experience in using Power BI, Tableau",
            "Strong communication skills to present insights effectively",
            "Problem-solving mindset with attention to detail",
            "Ability to collaborate with teams across business and technology",
            "Strong ability to interpret data and generate insights",
            "Business understanding to support decision-making",
        ],
        responsibilities: [      

        ]
    },
    {
        id: 11,
        position: "Assistant Tax Manager",
        experienceLevel: 4,
        experienceLevelName: "Min 4 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Kendal",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Tax Manager",
        requirements: [      
            "Minimum Bachelor's degree in Accounting, Taxation, or a related field",
            "Experience with export-import taxation",
            "Strong background in taxation analysis, tax audit, and tax compliance",
            "Strong background in tax regulations, including experience with income tax (Articles 21, 23/26, 4(2)) and VAT",
            "Fluent in English - Both Verbal and Written",
            "Willing to join ASAP",
            "Certified: Brevet A & B",
            "Proficient in English (both spoken and written)",
            "Willing to be fully on-site in Semarang/Kendal",
            "At least 5 years of experience as an Assistant Tax Manager",
            "Experience in the manufacturing industry",
        ],
        responsibilities: [      

        ]
    },
    {
        id: 12,
        position: "System Analyst",
        experienceLevel: 2,
        experienceLevelName: "Min 2 Years Experience in Multifinance Industries",
        jobType: 0,
        jobTypeName: "On Site Jakarta Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "Maximum age of 35 years old",
            "Bachelor’s degree or equivalent from any major with a minimum GPA of 3.00",
            "A minimum of 2 years of experience as a System Analyst in the Multifinance industry",
            "Proficient in planning, architecting, designing, estimating, and executing to ensure software development is successfully delivered to customers",
            "Capable of leading a team, mentoring team members, and ensuring tasks are completed effectively according to business needs",
            "Able to communicate clearly, accurately, and effectively both verbally and in writing",
            "Possesses in-depth knowledge of information technology, software development, and distributed frameworks, with enthusiasm for emerging technologies",
            "Able to identify and resolve technical issues in a highly adaptive environment",
            "Understands GIT, CI/CD, virtualization, and containerization technologies, and is proficient in using issue-tracking tools",
            "Understands Agile Mindset and Scrum Framework"
        ],
        responsibilities: [      

        ]
    },
    {
        id: 13,
        position: "Business Analyst",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years Experience In Multifinance Industries",
        jobType: 0,
        jobTypeName: "On Site Jakarta Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "A bachelor’s degree in business or related field or an MBA",
            "A minimum of 5 years’ experience in Business Analysis in Multifinance Industries",
            "Exceptional analytical and conceptual thinking skills",
            "The ability to influence stakeholders and work closely with them to determine acceptable solutions",
            "Excellent documentation skills",
            "Fundamental analytical and conceptual thinking skills",
            "Experience creating detailed reports and giving presentations",
            "Fluency in Microsoft Office suite (Outlook, Excel, Word, PowerPoint, etc.)",
            "Strong written, verbal, and collaboration skills",
        ],
        responsibilities: [      

        ]
    },
    {
        id: 14,
        position: "Project Manager",
        experienceLevel: 10,
        experienceLevelName: "Min 10 Years In Multifinance Industries Experience",
        jobType: 0,
        jobTypeName: "On Site Jakarta Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Software Engineering",
        requirements: [      
            "Minimum education of a Bachelor's degree in IT, Management, or a related field",
            "At least 10 years of experience in IT project management",
            "Must have a background in the Multifinance industry",
            "Strong analytical and project management skills, with in-depth knowledge of IT technology and proficiency in project management tools (e.g., Microsoft Project, Asana, Trello, Jira)",
            "Effective communication skills, both verbal and written",
            "Good interpersonal skills, including leadership, adaptability, and creative problem-solving",
            "Experience in project development methodologies such as Agile and Waterfall, as well as risk management and problem resolution",
            "Holding a PMP professional certification is a plus"
        ],
        responsibilities: [      

        ]
    },
    {
        id: 15,
        position: "Asst Manager / Supervisor Tax",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Tanggerang",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Asst Manager",
        requirements: [      
            "Proficient in DJP Online",
            "Knowledgeable in E-Faktur and the latest tax regulations",
            "Proficiency in SAP is preferred",
            "Holding a Brevet certificate is a plus (not mandatory)",
            "Able to handle SP2DK and Tax Audits",
            "Working days: Monday - Friday, 08:00 - 17:00 WIB"
        ],
        responsibilities: [      

        ]
    },
    {
        id: 16,
        position: "Warehouse Manager",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Tanggerang",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Warehouse Manager",
        requirements: [      
            "Five years of experience in a warehouse manufacturing company",
            "Have good knowledge of ERP System",
            "Excellent problem-solving skills and leadership qualities",
            "3 months probation",
            "Working days: Monday to Friday, 08:00 – 17:00",
            "Preferably have experience in bonded zone",
        ],
        responsibilities: [      

        ]
    },
    {
        id: 17,
        position: "Supervisor/Assitant Manager Maintenance",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years Experience",
        jobType: 0,
        jobTypeName: "On Site Tanggerang",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Supervisor/Assitant Manager Maintenance ",
        requirements: [      
            "Minimum D3/S1 degree to qualify for the Electrical K3 Expert Certificate",
            "Proficient in Strong Current, Weak Current, and PLC",
            "Skilled in using AutoCAD software",
            "Experience in a manufacturing company",
            "Willing to be placed in Bitung, Tangerang",
            "Able to work in shifts if required",
            "Working days: Monday - Friday (08:00 - 17:00), Saturday if needed"
        ],
        responsibilities: [      

        ]
    },
    {
        id: 18,
        position: "Partnership Manager",
        experienceLevel: 5,
        experienceLevelName: "Min 5 Years Experience In Digital Agency",
        jobType: 0,
        jobTypeName: "On Site Jakarta Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Supervisor",
        requirements: [      
            "A Bachelor’s degree in Marketing, Business, Economics, Communications, or a related field",
            "Minimum of 5 years of working experience in business development, strategic partnerships, or a related field in an advertising agency/digital marketing",
            "Proven track record of building successful partnerships and driving revenue growth",
            "Strong negotiation and communication skills, with the ability to open opportunities, and build and drive positive commercial outcomes with partners",
            "Excellent analytical and problem-solving skills with a data-driven mindset",
            "Ability to work collaboratively with cross-functional teams and manage multiple projects simultaneously",
            "Strong business acumen and ability to understand market trends and competitive landscape",
            "Must have an existing vendor, creative agency network, and digital media buy experience with a proven ability to build agency partnerships",
            "Fluent in English",
        ],
        responsibilities: [      

        ]
    },
    {
        id: 19,
        position: "Senior Social Media Specialist",
        experienceLevel: 3,
        experienceLevelName: "Min 3 Years Experience In Digital Agency",
        jobType: 0,
        jobTypeName: "On Site Jakarta Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Supervisor",
        requirements: [      
            "A Bachelor’s degree in Marketing, Business, Economics, Communications, or a related field",
            "Minimum of 3 years of experience in social media, experience in an advertising agency or digital marketing agency preferred",
            "Strong written and verbal communication skills, with the ability to adapt tone and style to suit different social media platforms",
            "Ability to turn social measurement into insights and create actionable, data-backed reports, compile results, and provide recommendations",
            "A creative mindset, with the ability to quickly identify trending topics and know when to engage",
            "Experience with social media publishing, listening, and analytics tools",
            "A detail-oriented approach, with the ability to manage multiple, high-profile projects and deliverables",
            "Experience working cross-functionally with internal teams and leadership to develop social media content and campaigns",
            "Provide a portfolio from previous social media work",
            "Fluent in English",
        ],
        responsibilities: [      

        ]
    },
    {
        id: 20,
        position: "Digital Account Manager",
        experienceLevel: 3,
        experienceLevelName: "Min 3 Years Experience In Digital Agency",
        jobType: 0,
        jobTypeName: "On Site Jakarta Barat",
        educationLevel: 2,
        educationLevelName: "Bachelor's Degree",
        type: 0,
        typeName: "Full Time",
        roles: "Supervisor",
        requirements: [      
            "Bachelor’s degree in Marketing, Business, Economics, Communications, or related field,",
            "3 years of experience in digital marketing is preferred,",
            "Proven track record of success in managing client accounts and delivering measurable results across various digital channels such as social media, PPC, SEO, email marketing, DSPs (DV360, Google Ads, TTD, etc.), Facebook Ads or TikTok Ads,",
            "Strong analytical skills and proficiency in leveraging data to drive decision-making and optimize campaign performance,",
            "Proven ability to identify upselling opportunities within existing client accounts and effectively communicate the value proposition of additional services or solutions,",
            "Demonstrate leadership qualities and project management skills,",
            "Willingness to stay updated on industry trends, emerging technologies, and best practices in digital marketing to remain at the forefront of the industry and drive innovation in client strategies,",
            "Provide previous portfolio from previous work of performance ads is preferred,",
            "Fluent in English,"
        ],
        responsibilities: [      

        ]
    },
];


