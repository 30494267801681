import styles from "../../../asset/styles/slider.module.css";
import ifg from "../../../asset/logo/ifg.png";
import nawadata from "../../../asset/logo/nawadata.png";
import accenture from "../../../asset/logo/Accenture.png";
import redcorp from "../../../asset/logo/redcorp.jpg";
import cacafly from "../../../asset/logo/cacafly.png";
import logo1 from "../../../asset/logo/logo (1).png";
import logo2 from "../../../asset/logo/logo (2).png";
import logo3 from "../../../asset/logo/logo (3).png";
import logo4 from "../../../asset/logo/logo (4).png";
import logo5 from "../../../asset/logo/logo (5).png";
import logo6 from "../../../asset/logo/logo (6).png";
import logo7 from "../../../asset/logo/logo (7).png";
import logo8 from "../../../asset/logo/logo (8).png";
import logo9 from "../../../asset/logo/logo (9).png";
import logo10 from "../../../asset/logo/logo (10).png";
import logo11 from "../../../asset/logo/logo (11).png";
import logo12 from "../../../asset/logo/logo (12).png";
import logo13 from "../../../asset/logo/logo (13).png";
import logo14 from "../../../asset/logo/logo (14).png";
import logo15 from "../../../asset/logo/logo (15).png";
import logo16 from "../../../asset/logo/logo (16).png";
import logo17 from "../../../asset/logo/logo (17).png";
import logo18 from "../../../asset/logo/logo (18).png";

const Clients = () => {
  const logos = [
    logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8,
    logo9, logo10, logo11, logo12, logo13, logo14, logo15,
    logo16, logo17, logo18
  ];

    return (
        <section className="bg-white py-12">
          <div className={`${styles.slider} [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]`}>
            <div className={`${styles.slideTrack} md:justify-start [&_li]:mx-8 [&_img]:max-w-none gap-3`}>
              <div className="px-5">
                <img src={ifg} alt="" className="h-10 px-2" />
              </div>
              <div className="px-5">
                <img src={nawadata} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={accenture} alt="" className="h-8"/>
              </div>
              <div className="px-5">
                <img src={redcorp} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={cacafly} alt="" className="h-8"/>
              </div>
              {logos.map((logo, index) => (
                <div key={index} className="px-5">
                  <img src={logo} alt={`Logo ${index + 1}`} className="h-10 px-2" />
                </div>
              ))}
              <div className="px-5">
                <img src={ifg} alt="" className="h-10 px-2" />
              </div>
              <div className="px-5">
                <img src={nawadata} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={accenture} alt="" className="h-8"/>
              </div>
              <div className="px-5">
                <img src={redcorp} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={cacafly} alt="" className="h-8"/>
              </div>
              <div className="px-5">
                  <img src={logo1} alt="" className="h-10 px-2" />
                </div>
            </div>
          </div>
        </section>
    );
};

export default Clients;